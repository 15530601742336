// // ************************************* START 2024 ROSE FM SECTION ****************************** //

import "swiper/swiper.min.css";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { serverLink } from "../../Resources/Url";
import { setLoginDetails } from "../../Actions/Actions";
import main_icon_1 from "../../Resources/main_icon_1.svg";
import LoginLoader from "../Shared/LoginLoader/LoginLoader";
import { Modal } from "react-st-modal";
import axios from "axios";
const Home = (props) => {
  const surveyFormStyle = {
    container: {
      borderRadius: "3%",
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "20px",
      flexDirection: "column",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: "9999,",
    },
    contentContainer: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      maxWidth: "100%",
    },
    questionContainer: {
      flex: 1,
      marginRight: "20px",
      marginBottom: "20px",
      maxWidth: "600px",
      width: "100%",
    },
    advertContainer: {
      flex: 1,
      marginLeft: "20px",
      marginBottom: "20px",
      maxWidth: "600px",
      width: "100%",
    },
    imageContainer: {
      marginRight: "10px",
    },
    image: {
      width: "80px",
      height: "80px",
      borderRadius: "50%",
      border: "2px solid #ddd",
      cursor: "pointer",
    },
    selectedImage: {
      // border: "0.5px solid #8a583c",
      border: "0.5px solid #8a583c",
      boxShadow: "0 0 10px rgba(150, 50, 50, 0.5)",
    },
    question: {
      marginBottom: "20px",
      fontSize: "20px",
      fontWeight: "bold",
      color: "white",
    },
    options: {
      display: "flex",
      flexDirection: "column",
    },
    option: {
      display: "flex",
      alignItems: "center",
      marginBottom: "10px",
    },
    station: {
      marginLeft: "auto",
      color: "#888",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
    },
    button: {
      backgroundColor: "#8a583c",
      color: "#fff",
      border: "none",
      padding: "10px 20px",
      borderRadius: "4px",
      marginRight: "10px",
      cursor: "pointer",
    },

    progress: {
      width: "100%",
      height: "10px",
      backgroundColor: "#ccc",
      marginBottom: "20px",
    },
    progressBar: {
      height: "100%",
      backgroundColor: "#8a583c",
    },
  };
  const [pageReloaded, setPageReloaded] = useState(false);
  const [phoneNumberMessage, setPhoneNumberMessage] = useState(null);
  const [userIPAddress, setUserIPAddress] = useState("");
  const votersExistingIPAddress =
    JSON.parse(localStorage.getItem("votersIPAddressAndPhoneNumber")) || [];

  const [createItem, setCreateItem] = useState({
    PhoneNumber: "",
    OAPId: "",
    OAPStationId: "",
    question1: "",
    question2: "",
    question3: "",
  });

  const onEdit = async (e) => {
    const id = e.target.id;
    const value = e.target.value;

    if (id === "PhoneNumber") {
      isPhoneNumberValid(value);
    }
    setCreateItem({
      ...createItem,
      [id]: value,
    });
  };

  const [showCountdown, setShowCountdown] = useState(true);
  const [countdown, setCountdown] = useState("");

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [oap] = useState(
    typeof props.HomePageData.oap !== "undefined" ? props.HomePageData.oap : []
  );

  const [question] = useState(
    typeof props.HomePageData.questions !== "undefined"
      ? props.HomePageData.questions
      : []
  );

  const [election] = useState(
    typeof props.HomePageData.election !== "undefined"
      ? props.HomePageData.election
      : []
  );

  const activeElectionData = election.filter((x) => x.StationId === Number(3));

  const [votes] = useState(
    typeof props.HomePageData.votes !== "undefined"
      ? props.HomePageData.votes
      : []
  );

  const isPhoneNumberValid = (phoneNumber) => {
    // const isValid = isPossiblePhoneNumber(phoneNumber);

    if (phoneNumber.length === 11) {
      setPhoneNumberMessage("valid");
    } else {
      setPhoneNumberMessage("invalid");
    }
  };

  const eligibleOAPs = oap.filter((x) => x.StationId === Number(3));

  const currentUserData = votes.filter((x) => x.StationId === 3);

  const existingUserFromDB = currentUserData.find(
    (x) =>
      (x.IpAddress === userIPAddress.toString() && x.StationId === 3) ||
      x.PhoneNumber === createItem.PhoneNumber.toString()
  );

  // MOVE THIS TO ON SUBMIT
  // if (existingUserFromDB) {
  //   // window.location.href = "https://www.results.enyinba.com/results";
  //   window.alert("You have already submitted")
  //   // navigate("/results");
  // }

  const [pollResults] = useState(
    typeof props.HomePageData.votes !== "undefined"
      ? props.HomePageData.votes
      : []
  );

  const convertToDate = (dateString) => {
    const [day, month, year] = dateString.split("/");
    return new Date(`${month}/${day}/${year}`);
  };

  useEffect(() => {
    let countdownInterval;

    const currentDate = new Date();
    // const targetDate = new Date("July 20, 2023");
    const targetDate = convertToDate(activeElectionData[0].StartDate);

    if (currentDate >= targetDate) {
      setShowCountdown(false);
    } else {
      countdownInterval = setInterval(() => {
        const timeRemaining = targetDate - new Date();
        const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

        setCountdown(
          `${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`
        );

        if (timeRemaining <= 0) {
          clearInterval(countdownInterval);
          setShowCountdown(false);
        }
      }, 1000);
    }

    return () => {
      clearInterval(countdownInterval);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  /// -----------Start---------------- ///
  const calculateTotalVotes = (data) => {
    const staffVotes = {};

    for (const vote of data) {
      const staffId = vote.StaffId;
      const stationId = vote.StationId;
      const key = `${staffId}-${stationId}`;

      if (!staffVotes[key]) {
        staffVotes[key] = 0;
      }

      staffVotes[key]++;
    }

    const staffArray = Object.entries(staffVotes).map(([key, totalVotes]) => {
      const [staffId, stationId] = key.split("-");
      return {
        StaffId: parseInt(staffId),
        StationId: parseInt(stationId),
        TotalVotes: totalVotes,
      };
    });

    return staffArray;
  };

  const processDataAndInsertIntoTable = () => {
    const processedVotes = calculateTotalVotes(pollResults);

    // Call the endpoint to insert the processed data into the table
    axios
      .post(`${serverLink}vote/insertProcessedData`, processedVotes)
      .then((response) => {
        // console.log("Data inserted successfully", response);
      })
      .catch((error) => {
        console.error("Error inserting data: ", error);
      });
  };

  useEffect(() => {
    processDataAndInsertIntoTable();
  }, []);
  /// --------------Ends------------------- ///

  useEffect(() => {
    const fetchUserIPAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        const { ip } = response.data;
        setUserIPAddress(ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };
    fetchUserIPAddress().then((r) => {});
  }, []);

  useEffect(() => {
    if (!pageReloaded && votersExistingIPAddress === 0) {
      setPageReloaded(true);
      window.location.reload();
    }

    if (
      !votersExistingIPAddress ||
      Object.keys(votersExistingIPAddress).length === 0
    ) {
      setIsOpen(true);
    }
  }, [votersExistingIPAddress, pageReloaded]);

  const [
    isSubmitPhoneNumberAndIppAddress,
    setIsSubmitPhoneNumberAndIppAddress,
  ] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function closeModalAfterGottenIP() {
    setIsOpen(false);
  }

  function closeVotingSystem() {
    if (
      !votersExistingIPAddress ||
      Object.keys(votersExistingIPAddress).length === 0
    ) {
      window.location.href = "https://results.enyinba.com/results";
    }
  }

  const onAttemptClose = () => {
    window.alert(`Please enter phone number and click on start voting!`);
  };

  const onAttemptCloseYouHaveVoted = () => {
    // window.alert(`Please click on either "View Results" or "Next Station!"`);
  };

  const closeYouHaveVotedModel = () => {
    // window.alert(`Please click on either "View Results" or "Proceed!"`);
  };

  const onCancel = async () => {
    window.location.href = "https://results.enyinba.com/results";
  };

  const submitPhoneNumberAndIppAddress = async (e) => {
    e.preventDefault();
    setIsSubmitPhoneNumberAndIppAddress(true);

    for (let key in createItem) {
      if (
        createItem.hasOwnProperty(key) &&
        key !== "PurchasePeriodPrice" &&
        key !== "OAPId" &&
        key !== "OAPStationId" &&
        key !== "question1" &&
        key !== "question2" &&
        key !== "question3"
      ) {
        if (createItem[key] === "") {
          setTimeout(async () => {
            setIsSubmitPhoneNumberAndIppAddress(false);
            window.alert(`Enter phone number to continue`);
          }, 1000);
          return false;
        }
      }
    }

    if (phoneNumberMessage === "invalid") {
      window.alert("Invalid Phone Number");
      return false;
    }

    const existingUser = currentUserData.find(
      (x) =>
        (x.IpAddress === userIPAddress.toString() &&
          x.StationId === Number(3)) ||
        x.PhoneNumber === createItem.PhoneNumber.toString()
    );

    if (existingUser && Object.keys(existingUser).length > 0) {
      setTimeout(async () => {
        setIsSubmitPhoneNumberAndIppAddress(false);
        window.alert(`You have already voted`);
        window.location.href = "https://results.enyinba.com/results";
      }, 1000);
      return false;
    }

    const newItem = {
      PhoneNumber: createItem.PhoneNumber.toString(),
      IpAddress: userIPAddress.toString(),
      ElectionId: activeElectionData[0].ElectionId,
      StationId: Number(3),
    };

    setTimeout(async () => {
      localStorage.setItem(
        "votersIPAddressAndPhoneNumber",
        JSON.stringify(newItem)
      );

      setTimeout(async () => {
        setIsSubmitPhoneNumberAndIppAddress(false);
        closeModalAfterGottenIP();
        window.location.reload();
      }, 2000);
    }, 1000);
  };

  const questions = question.map((question) => {
    return {
      id: question.QuestionId,
      question: question.QuestionText,
      options: eligibleOAPs.map((option) => {
        return { ...option, QuestionId: question.QuestionId };
      }),
      answer: null,
    };
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitPhoneNumberAndIppAddress(true);
    // Check if all questions have been answered
    const unansweredQuestions = questions.filter(
      (question) => !answers[question.id]
    );

    if (unansweredQuestions.length > 0) {
      window.alert("Please answer all the questions.");
      return;
    }

    const voteData = {
      IpAddress: votersExistingIPAddress.IpAddress,
      PhoneNumber: votersExistingIPAddress.PhoneNumber,
      ElectionId: activeElectionData[0].ElectionId,
      Answers: Object.values(answers),
    };

    fetch(`${serverLink}vote/submit-vote`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(voteData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "success") {
          setTimeout(async () => {
            setIsSubmitPhoneNumberAndIppAddress(false);
            processDataAndInsertIntoTable();
            window.alert("Your vote is submitted successfully.");
            await setIsOpen(false);
            localStorage.removeItem("votersIPAddressAndPhoneNumber");
            setIsOpen(false);
            window.location.reload();
            window.location.href = "https://results.enyinba.com/results";
            /// navigate("/results");
            setTimeout(async () => {}, 2000);
          }, 1000);
        }

        if (data.message === "error") {
          setTimeout(async () => {
            window.alert(
              "Sorry, vote not submitted successfully, kindly close your browser and try again"
            );
            setTimeout(async () => {
              setIsSubmitPhoneNumberAndIppAddress(false);
              closeModalAfterGottenIP();
            }, 2000);
          }, 1000);
        }
      })
      .catch((error) => {
        console.error("Error submitting survey:", error);
        // Handle any error message or redirect the user to an error page
      });
  };

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const currentQuestion = questions[currentQuestionIndex];

  const getProgress = () => {
    const progress = ((currentQuestionIndex + 1) / questions.length) * 100;
    return `${progress}%`;
  };

  const handleOptionChange = (e) => {
    const selectedAnswer = JSON.parse(e.target.value);
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [currentQuestion.id]: selectedAnswer,
    }));
  };

  const viewResults = (e) => {
    window.location.href = "https://results.enyinba.com/results";
  };

  const proceedToVoting = (e) => {
    window.location.href = "https://vote.enyinba.com";
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    }
  };

  const renderOptions = () => {
    return currentQuestion.options.map((option) => (
      // const imageLink = option;
      <div
        // OAPId, FullName, EmailAddress, PhoneNumber, Passport, Password, IsActive, StationId
        key={option.OAPId}
        style={{
          ...surveyFormStyle.option,
          ...(answers[currentQuestion.id] &&
          answers[currentQuestion.id].OAPId === option.OAPId
            ? surveyFormStyle.selectedImage
            : {}),
        }}
      >
        <div
          style={surveyFormStyle.imageContainer}
          onClick={() =>
            handleOptionChange({ target: { value: JSON.stringify(option) } })
          }
        >
          {oap.length > 0 &&
            oap
              .filter((i) => i.OAPId === Number(option.OAPId))
              .map((x) => (
                <img
                  src={`${option.Passport}`}
                  alt={option.FullName}
                  style={{
                    ...surveyFormStyle.image,
                    ...(answers[currentQuestion.id] &&
                    answers[currentQuestion.id].OAPId === option.OAPId
                      ? surveyFormStyle.selectedImage
                      : {}),
                  }}
                />
              ))[0]}
        </div>
        <label htmlFor={option.OAPId}>
          <span>{option.FullName}</span>
        </label>
      </div>
    ));
  };

  const renderCountdown = () => {
    // return <div>Countdown: {countdown}</div>;
    return (
      <>
        <main>
          <div className="container-fluid" style={{ marginTop: "30px" }}></div>
          <div className="wrapper_centering p-3">
            <div className="container_centering">
              <div className="container">
                <div className="row justify-content-between">
                  <div className="col-xl-6 col-lg-6 d-flex align-items-center">
                    <div className="main_title_1">
                      <h4
                        style={{
                          borderRadius: "3%",
                          overflow: "hidden",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "20px",
                          flexDirection: "column",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          color: "white",
                          fontSize: "20px",
                          textAlign: "center",
                        }}
                      >
                        <strong>Voting Guideline</strong>{" "}
                      </h4>

                      <p>
                        To participate, use the candidate election panel where
                        the countdown is displayed: Click on your chosen
                        candidate for each question and proceed to the next.
                        Once you have answered all the questions, click on the{" "}
                        <strong>"submit"</strong> button to finalize your
                        voting.
                      </p>
                      <p>
                        <em>- Signed Management -</em>
                      </p>
                    </div>
                  </div>
                  {/*VOTERS FORM START*/}
                  <div className="col-xl-5 col-lg-5">
                    <div
                      style={{
                        borderRadius: "3%",
                        overflow: "hidden",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "20px",
                        flexDirection: "column",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        color: "white",
                        fontSize: "20px",
                        textAlign: "center",
                        zIndex: "9999,",
                        height: "500px",
                      }}
                    >
                      <strong>Voting Start In</strong> <br />{" "}
                      <div
                        style={{
                          backgroundColor: "rgba(213,24,24,0.5)",
                          borderRadius: "3%",
                        }}
                      >
                        <strong>{countdown}</strong>
                      </div>
                    </div>
                  </div>
                  {/*VOTERS FORM END*/}
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  };

  const renderQuestion = () => {
    const isFirstQuestion = currentQuestionIndex === 0;
    const isLastQuestion = currentQuestionIndex === questions.length - 1;

    return (
      <form onSubmit={handleSubmit}>
        <div style={surveyFormStyle.contentContainer}>
          <div style={surveyFormStyle.questionContainer}>
            <h3 style={surveyFormStyle.question}>{currentQuestion.question}</h3>
            <div style={surveyFormStyle.options}>{renderOptions()}</div>
          </div>
        </div>
        <div style={surveyFormStyle.buttonContainer}>
          {!isFirstQuestion && (
            <button
              type="button"
              className="w-50"
              style={surveyFormStyle.button}
              onClick={handlePrevious}
            >
              Previous
            </button>
          )}
          {!isLastQuestion ? (
            <button
              type="button"
              className="w-50"
              style={surveyFormStyle.button}
              onClick={handleNext}
              disabled={!answers[currentQuestion.id]}
            >
              Next
            </button>
          ) : (
            <>
              <button
                type="submit"
                style={surveyFormStyle.button}
                disabled={!answers[currentQuestion.id]}
              >
                Submit
              </button>
              {isSubmitPhoneNumberAndIppAddress && <LoginLoader />}
            </>
          )}
        </div>
      </form>
    );
  };

  const renderCompletedMessage = () => {
    return <h3>Thank you!</h3>;
  };

  return (
    <>
      {existingUserFromDB ? (
        <div
          className="tp-modal-overlay"
          style={{
            border: "1px solid #D5D8DB",
            color: "#010F1C",
            height: "100%",
            width: "100%",
            position: "fixed",
            top: 0,
            left: 0,
            backgroundColor: "rgba(0, 0, 0, 0.70)",
            zIndex: "9999",
          }}
        >
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeYouHaveVotedModel()}
            onAttemptClose={onAttemptCloseYouHaveVoted}
            style={{
              content: {
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "746px",
                zIndex: "9999",
              },
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: "9999",
              },
            }}
          >
            <div
              style={{
                width: "100%",
                maxWidth: "800px",
                margin: "0 auto",
                // width: "500px",
                // height: "150px",
                marginTop: "5%",
                marginLeft: "5%",
              }}
            >
              <div className="tp-return-customer-input">
                <label
                  style={{
                    color: "black",
                  }}
                >
                  You have already voted for this station!
                </label>
                <br />
              </div>

              <div
                style={{
                  marginTop: "10px",
                }}
              >
                <button
                  onClick={viewResults}
                  type="submit"
                  className="tp-return-customer-btn tp-checkout-btn"
                  style={{
                    backgroundColor: "#692f2f",
                    // backgroundColor: "#4CAF50",
                    color: "white",
                    border: "none",
                    padding: "10px 20px",
                    borderRadius: "4px",
                    fontSize: "14px",
                    cursor: "pointer",
                    marginRight: "2px",
                    width: "45%",
                  }}
                >
                  View Results
                </button>

                <button
                  onClick={proceedToVoting}
                  type="submit"
                  className="tp-return-customer-btn tp-checkout-btn"
                  style={{
                    backgroundColor: "#8a583c",
                    // backgroundColor: "#4CAF50",
                    color: "white",
                    border: "none",
                    padding: "10px 20px",
                    borderRadius: "4px",
                    fontSize: "14px",
                    cursor: "pointer",
                    marginBottom: "10px",
                    width: "45%",
                  }}
                >
                  Next Station
                </button>
              </div>
              {isSubmitPhoneNumberAndIppAddress && <LoginLoader />}
            </div>
          </Modal>
        </div>
      ) : (
        <div style={{ overflowY: "scroll" }}>
          {showCountdown ? (
            renderCountdown()
          ) : (
            <>
              <main>
                <div
                  className="container-fluid"
                  style={{ marginTop: "30px" }}
                ></div>
                <div className="wrapper_centering p-3">
                  <div className="container_centering">
                    <div className="container">
                      <div className="row justify-content-between">
                        <div className="col-xl-6 col-lg-6 d-flex align-items-center">
                          <div className="main_title_1">
                            <h3>
                              <img
                                src={main_icon_1}
                                width="80"
                                height="80"
                                alt=""
                              />{" "}
                              {activeElectionData[0].ElectionTitle}
                            </h3>
                            <p>{activeElectionData[0].Description}</p>

                            <p>
                              <strong>
                                To participate, use the candidate election
                                panel:
                              </strong>{" "}
                              <br />
                              Click on your chosen candidate for each question
                              and proceed to the next. Once you have answered
                              all the questions, click on the{" "}
                              <strong>"submit"</strong> button to finalize your
                              voting.
                            </p>
                            <p>
                              <em>- Signed Management -</em>
                            </p>
                          </div>
                        </div>
                        {/*VOTERS FORM START*/}
                        <div className="col-xl-5 col-lg-5">
                          <div style={surveyFormStyle.container}>
                            <div style={surveyFormStyle.progress}>
                              <div
                                style={{
                                  ...surveyFormStyle.progressBar,
                                  width: getProgress(),
                                }}
                              ></div>
                            </div>
                            {currentQuestionIndex < questions.length
                              ? renderQuestion()
                              : renderCompletedMessage()}
                          </div>
                        </div>
                        {/*VOTERS FORM END*/}
                      </div>
                    </div>
                  </div>
                </div>
              </main>

              <div
                id="tpReturnCustomerLoginForm"
                className="tp-return-customer"
                style={{
                  position: "relative",
                  zIndex: "9999",
                }}
              >
                {modalIsOpen && (
                  <div
                    className="tp-modal-overlay"
                    style={{
                      border: "1px solid #D5D8DB",
                      color: "#010F1C",
                      height: "100%",
                      width: "100%",
                      position: "fixed",
                      top: 0,
                      left: 0,
                      backgroundColor: "rgba(0, 0, 0, 0.70)",
                      zIndex: "9999",
                    }}
                  >
                    <Modal
                      isOpen={modalIsOpen}
                      onRequestClose={closeVotingSystem}
                      onAttemptClose={onAttemptClose}
                      style={{
                        content: {
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: "746px",
                          zIndex: "9999",
                        },
                        overlay: {
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          zIndex: "9999",
                        },
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          maxWidth: "800px",
                          margin: "0 auto",
                          // width: "500px",
                          // height: "150px",
                          marginTop: "5%",
                          marginLeft: "5%",
                        }}
                      >
                        <div className="tp-return-customer-input">
                          <label
                            style={{
                              color: "black",
                            }}
                          >
                            Enter Phone Number
                          </label>
                          <input
                            type="text"
                            placeholder="Must be 11 digits: 08136791100"
                            name="PhoneNumber"
                            id="PhoneNumber"
                            value={createItem.PhoneNumber}
                            onChange={onEdit}
                            style={{
                              width: "90%",
                              padding: "8px",
                              marginBottom: "10px",
                            }}
                          />
                          <br />
                          {phoneNumberMessage === "invalid" && (
                            <span
                              style={{
                                backgroundColor: "red",
                                color: "white",
                                width: "90%",
                                padding: "8px",
                                marginBottom: "10px",
                                // fontSize: "12px",
                                // padding: "5px",
                                // marginTop: "5px",
                              }}
                            >
                              {phoneNumberMessage && (
                                <>
                                  {phoneNumberMessage === "invalid" &&
                                    "Must be 11 digits: 08136791100"}
                                </>
                              )}
                            </span>
                          )}
                        </div>

                        <div
                          style={{
                            marginTop: "10px",
                          }}
                        >
                          <button
                            onClick={onCancel}
                            type="submit"
                            className="tp-return-customer-btn tp-checkout-btn"
                            style={{
                              backgroundColor: "#692f2f",
                              // backgroundColor: "#4CAF50",
                              color: "white",
                              border: "none",
                              padding: "10px 20px",
                              borderRadius: "4px",
                              fontSize: "16px",
                              cursor: "pointer",
                              marginRight: "2px",
                              width: "45%",
                            }}
                          >
                            Cancel
                          </button>

                          <button
                            onClick={submitPhoneNumberAndIppAddress}
                            type="submit"
                            className="tp-return-customer-btn tp-checkout-btn"
                            style={{
                              backgroundColor: "#8a583c",
                              // backgroundColor: "#4CAF50",
                              color: "white",
                              border: "none",
                              padding: "10px 20px",
                              borderRadius: "4px",
                              fontSize: "16px",
                              cursor: "pointer",
                              marginBottom: "10px",
                              width: "45%",
                            }}
                          >
                            Start Voting
                          </button>
                        </div>
                        {isSubmitPhoneNumberAndIppAddress && <LoginLoader />}
                      </div>
                    </Modal>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);

// // ********************************* END 2024 ROSE FM SECTION *********************** //
